<template>
    <div class="how-it-works">
        <div id="Content">
            <div class="shadow"></div>
            <div class="content-inner">
                <p>Welcome to the tutorials section of INCREDEVENT. Here, you can learn how to use the many features of our website. We've organized this page into three sections; Buyers, Sellers, and Promoters/Vendors. Any question you have about the functionality of our website can be answered on this page. If, for some reason, you can't find your answer below, please send us a message and we'll be sure to fill you in.</p>
                <p><b>How to use YouTube Playlists:</b>
                    <ol>
                        <li>Click on the play button to play the first video.</li>
                        <li>If you'd like to see all of the videos in the playlist, click on the 3 lines and play icon in the top left corner.</li>
                        <li>You can select any video to watch from the menu that pops up.</li>
                    </ol>
                </p>
                <h2>Terminology:</h2>
                <p>
                    <strong>Sellers</strong> - Admins over events.<br>
                    <strong>Buyers</strong> - Customers who purchase tickets.<br>
                    <strong>Promoters</strong> - Admins add promoters to events to sell tickets.<br>
                    <strong>Vendors</strong> - Admins can add commercial stores as vendors to sell tickets.
                </p>
                <p>So, without further ado, here's the information that will assist you in buying or selling tickets, and we've included security info as well.</p>
                <div class="tutorials">
                    <div class="tutorial">
                        <h1>Buyer Tutorials</h1>
                        <p>To navigate the playlist please click on the icon in the top left to view all videos within the list.</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/NJ6ZIPJVB54" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="tutorial">
                        <h1>Coordinator Tutorials</h1>
                        <p>To navigate the playlist please click on the icon in the top left to view all videos within the list.</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/8ucyEx9m8CY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="tutorial">
                        <h1>Promoter &amp; Vendor Tutorials</h1>
                        <p>To navigate the playlist please click on the icon in the top left to view all videos within the list.</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/FVGR_nxjH84" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
ol {
  margin-top: 20px;
  padding-left: 40px;
}
.how-it-works {

  .tutorials {
    display: flex;
    flex-wrap: wrap;

    .tutorial {
      margin: 1em auto;
      width: 96%;

      h1,
      p {
        text-align: center;
      }

      h1 {
        margin-bottom: 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 1.5em;
      }

      iframe {
        display: block;
        width: 100%;
        min-height: 500px;
      }
    }
    // end tutorial
  }
  // end tutorials
}
// end how-it-works
</style>

<script>
export default {
  name: "how-it-works",
  head() {
    return {
      title: "How it Works"
    };
  },
  created() {
    this.$store.commit("setTitle", "How It Works");
  }
};
</script>
